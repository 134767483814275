<template>
  <div class="main-container">
    <div class="banner">
      <img src="./../assets/logo-home.png" class="home-logo" alt="logo home" />
    </div>  
    <section class="main-section">
      <div class="col-section">        
        <div class="text-container">
          <h2>NOTRE METIER : LA TRANSMISSION</h2>
          <p>Nous mettons en relation et accompagnons les cédants et repreneurs de commerces, d’entreprises et d’immobilier d’entreprise dans un cadre de maîtrise, pertinence, optimisation et succès.</p> 
          <p><b>Facil'Immo</b>, fort de plus de 12 ans d’expérience, est un acteur majeur de la transmission connu et reconnu. Nos clients, en France et à l’étranger, sont très souvent acquéreur un jour, cédant un autre jour.</p>
          <p>Nous les accompagnons pour la plupart depuis de nombreuses années.</p>
        </div>
      </div>
      <div class="col-section section-metier">
        <div class="gradient"></div>
      </div>
    </section>
    <section class="main-section section-gray section-reverse">
      <div class="col-section">
        <div class="text-container">
          <h2>UN LARGE PERIMETRE D’ACTION</h2>
          <p>L’action historique de <b>Facil'Immo</b> a été centrée sur la transmission de commerces de proximité (bars/tabacs, hôtels, restaurants, brasseries, boulangeries, …).</p>
          <p>Cette activité reste encore dominante aujourd’hui en nombre d’affaires traitées.</p>
          <p>Le succès et la notoriété grandissante ont conduit <b>Facil'Immo</b> à prendre en charge depuis plusieurs années la transmission de biens d’envergure tels que des murs de centres commerciaux, des ensembles hôteliers d’exception voire des biens totalement atypiques.</p>
          <p>Le traitement de ce type de biens exige une expertise spécifique et un fonctionnement beaucoup plus confidentiel. <b>Facil'Immo</b> s’est structuré afin d’y répondre avec une efficacité maximale en créant un pôle  dédié «<router-link class="text-link" to="/premium">Facil'Immo Premium</router-link>».</p>
          <router-link to="/premium" class="btn">espace «premium»</router-link>
        </div>
      </div>
      <div class="col-section section-perimetre">
        <div class="gradient"></div>
      </div>
    </section>
    <section class="main-section">
      <div class="col-section">
        <div class="text-container">
          <h2>UNE FORCE : L’EXPERTISE ET LE RESEAU</h2>
          <p>La réussite des transmissions basée sur notre expertise engendre la confiance sans cesse renouvelée de nos clients.</p>
          <p>Ce socle couplé au développement permanent de notre réseau d’investisseurs et avocats d’affaires, permet à <b>Facil'Immo</b> de disposer d’un solide portefeuille de cédants et acquéreurs récurrents constitué d’individuels et de financières. </p>
          <p>Cet outil clé, accroit l’efficacité de la mise en adéquation cédant / acquéreur et engendre des opportunités d’affaires au sein même du portefeuille.</p>
        </div>
      </div>
      <div class="col-section section-reseau">
        <div class="gradient"></div>
      </div>
    </section>
    <section class="call-action">
      <div class="action-text">
        <h3>Découvrez également notre espace "Premium" :<br />
            La transmission de biens de grande envergure ou exceptionnels</h3>
      </div>
      <div class="action-btn">
        <router-link to="/premium" class="btn">en savoir +</router-link>
      </div>
    </section>
    <section class="contact-section">
      <h2>Contactez-nous</h2>
      <div class="contact-row">
        <div class="contact-col">
          <div class="contact-item">
            <div class="contact-name">Eddie REBAI</div>
            <i>Fondateur</i><br />
            +33 6 68 54 14 17<br />
            <a href="mailto:e.rebai@cabinet-facilimmo.com">e.rebai@cabinet-facilimmo.com</a>
          </div>
          <div class="contact-item">
            <div class="contact-name">Corinne BONNAVENT</div>
            <i>Chargée d’Affaire</i><br />
            +33 6 61 42 64 86<br />
            <a href="mailto:c.bonnavent@cabinet-facilimmo.com">c.bonnavent@cabinet-facilimmo.com</a>
          </div>
        </div>
        <div class="contact-col">
            <ContactForm></ContactForm>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContactForm from './../components/ContactForm.vue'

export default {
  name: 'Home',
  components: {
    ContactForm
  }

}
</script>

<style lang="scss" scoped>
  .banner {
    background-image: url('./../assets/banner-home.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .banner img {
    margin: 10px auto;
  }

  .home-logo {
    width:400px;
    height:auto;
    max-width: 90%;
  }

  .section-metier {
    background-image:url('./../assets/home-section-1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .section-reseau {
    background-image:url('./../assets/home-section-3.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .section-perimetre {
    background-image:url('./../assets/home-section-2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .text-link {
    color:$main-color;
    text-decoration: none;
  }

  .text-container a:hover {
    text-decoration: underline;
  }

  .text-container .btn {
    margin-top:20px;
  }

  .call-action {
    padding:40px 20px;
    display: flex;
    background:$secondary-color;
    align-items: center;
  }

  .action-text {
    width:60%;
    max-width: 1000px;
    margin-left:10%;
  }

  .action-text h3 {
    color:#fff;
    font-size:35px;
    font-weight:400;
  }

  .action-btn {
    display: flex;
    justify-content: center;
    width: 40%;
  }

  .contact-section {
    padding:80px 0;
    background-image: url('./../assets/bg-contact-form-home.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-section h2 {
    text-align: center;
    color:#fff;
    font-size:35px;
    font-weight:500;
    margin:0;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .contact-row {
    width:100%;
    display: flex;
    align-items: center;
  }

  .contact-col:nth-child(1) {
    width:40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .contact-col:nth-child(2) {
    width:60%;
  }

  .contact-item {
    color:#fff;
    font-weight: 400;
    font-size:18px;
    margin:20px 0 20px 20px;
    width: 300px;
  }

  .contact-name {
    font-weight: 500;
    font-size:22px;
  }

  .contact-item a {
    color:#fff;
    text-decoration:none;
  }

  .contact-item a:hover {
    text-decoration:underline;
  }

  @media screen and (max-width:768px) {
    .contact-item {
      margin: 20px auto;
      width:90%;
      text-align: center;
    }

    .contact-row  {
      flex-direction: column;
    }

    .contact-col:nth-child(1), .contact-col:nth-child(2) {
      width:100%
    }

    .contact-col:nth-child(1) {
      align-items: flex-start;
    }

    .contact-section h2 {
      font-size: 25px;
    }

    .call-action {
      flex-direction: column;
    }

    .action-text {
      width: 100%;
      margin:0;
    }

    .action-text h3 {
      font-size: 25px;
      text-align: center;
    }
  }
</style>
